import React from 'react';
import Slider from 'react-slick';

import css from './style.module.scss';

const CarriersCarousel = () => {
  const settings = {
    autoplay: true,
    autoplaySpeed: 6000,
    dots: true,
    focusOnSelect: false,
    infinite: true,
    slidesToScroll: 5,
    slidesToShow: 5,
    speed: 500,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          autoplay: true,
          autoplaySpeed: 6000,
          dots: false,
          focusOnSelect: false,
          infinite: true,
          slidesToScroll: 1,
          slidesToShow: 1,
          speed: 500
        }
      },
      {
        breakpoint: 768,
        settings: {
          autoplay: true,
          autoplaySpeed: 6000,
          dots: false,
          focusOnSelect: false,
          infinite: true,
          slidesToScroll: 2,
          slidesToShow: 2,
          speed: 500
        }
      },
      {
        breakpoint: 1024,
        settings: {
          autoplay: true,
          autoplaySpeed: 6000,
          dots: false,
          focusOnSelect: false,
          infinite: true,
          slidesToScroll: 3,
          slidesToShow: 3,
          speed: 500
        }
      },
      {
        breakpoint: 1200,
        settings: {
          autoplay: true,
          autoplaySpeed: 6000,
          dots: false,
          focusOnSelect: false,
          infinite: true,
          slidesToScroll: 4,
          slidesToShow: 4,
          speed: 500
        }
      }
    ]
  };
  return (
    <Slider {...settings}>
      <div className={css.imageContainerAetna} />
      <div className={css.imageContainerAnthem} />
      <div className={css.imageContainerAssurity} />
      <div className={css.imageContainerBcbs} />
      <div className={css.imageContainerCigna} />
      <div className={css.imageContainerCoventry} />
      <div className={css.imageContainerHumana} />
      <div className={css.imageContainerMedica} />
      <div className={css.imageContainerNationalGeneral} />
      <div className={css.imageContainerUnitedHealthcare} />
    </Slider>
  );
};

export default CarriersCarousel;
