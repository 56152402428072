import React from 'react';

import CarriersCarousel from '../../carriers-carousel';

export default props => (
  <div className='home'>
    <div className='home-content'>
      <div id='home' className='anchor' />
      <div className='sky'></div>
      <div className='headings-container'>
        <div className='headings'>
          <div className='msg1'>START YOUR COVERAGE NOW.</div>
          <div className='msg2'>Protection that is built around your needs.</div>
          <a href='/insurance' className='planning-button cursor-on-hover'>
            START PLANNING
          </a>
          <div
            className='quote-button'
            onClick={() => {
              props.setGetAQuoteOpened && props.setGetAQuoteOpened(!props.getAQuoteOpened);
            }}>
            GET A QUOTE
          </div>
        </div>
      </div>
    </div>

    <div className='home-carriers'>
      <div className='home-carriers-title'>WE WORK WITH THE BEST PARTNERS</div>
      <CarriersCarousel />
    </div>
  </div>
);
